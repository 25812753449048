@use 'variables';

:root {
  --primary: #{variables.$primary};
  --primary-mid: #{variables.$primary-mid};
  --primary-light: #{variables.$primary-light};
  --primary-dark: #{variables.$primary-dark};
  --primary-shade: #{variables.$primary-shade};
  --primary-shadow: #{variables.$primary-shadow};
  --primary-lighter: #{variables.$primary-lighter};
  --secondary: #{variables.$secondary};
  --secondary-dark: #{variables.$secondary-dark};
  --success: #{variables.$success};
  --error: #{variables.$error};

  --input-shadow: 0px -0.5px 1px 0px rgba(0, 0, 0, 0.2) inset,
  0px -0.5px 1px 0px rgba(0, 0, 0, 0.3) inset,
  1px 1.5px 4px 0px rgba(0, 0, 0, 0.125) inset,
  1px 1.5px 4px 0px rgba(0, 0, 0, 0.125) inset;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

iframe {
  padding: 0;
  margin: 0;
  border: none;
}

@layer base {
  a {
    @apply text-inherit no-underline;
    -webkit-tap-highlight-color: transparent;
  }

  hr {
    @apply relative mx-0 my-4 border-white opacity-25;
  }
}

html {
  @apply bg-primary text-white;
  overflow: auto;
  &.app-game {
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }
}
body {
  overflow: visible;
  position: static;
}

/* Hide ZenDesk chat button */
body iframe[id='launcher'] {
  visibility: hidden !important;
  top: -9999px !important;
  opacity: 0 !important;
}

/* width */
::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.15);
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary-light;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-light;
}

.pac-container {
  @apply bg-black;
}
.pac-item,
.pac-item-query {
  @apply p-2 text-white;
}
.pac-item:hover {
  @apply bg-black/70;
}

.hidePag + .pagi {
  visibility: hidden;
}
